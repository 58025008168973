"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.datavizPalette = void 0;
var _seedsColor = _interopRequireDefault(require("@sproutsocial/seeds-color"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var datavizPalette = exports.datavizPalette = {
  DATAVIZ_COLORS_LIST: [_seedsColor.default.COLOR_TEAL_400, _seedsColor.default.COLOR_PURPLE_400, _seedsColor.default.COLOR_PINK_400, _seedsColor.default.COLOR_YELLOW_500, _seedsColor.default.COLOR_BLUE_400, _seedsColor.default.COLOR_MAGENTA_400, _seedsColor.default.COLOR_GREEN_400, _seedsColor.default.COLOR_ORANGE_400, _seedsColor.default.COLOR_RED_700, _seedsColor.default.COLOR_TEAL_700, _seedsColor.default.COLOR_PURPLE_700, _seedsColor.default.COLOR_PINK_700, _seedsColor.default.COLOR_YELLOW_800, _seedsColor.default.COLOR_BLUE_700, _seedsColor.default.COLOR_MAGENTA_700, _seedsColor.default.COLOR_GREEN_700, _seedsColor.default.COLOR_ORANGE_700, _seedsColor.default.COLOR_RED_400, _seedsColor.default.COLOR_GREEN_200, _seedsColor.default.COLOR_YELLOW_200],
  DATAVIZ_COLORS_MAP: {
    "1": _seedsColor.default.COLOR_TEAL_400,
    "2": _seedsColor.default.COLOR_PURPLE_400,
    "3": _seedsColor.default.COLOR_PINK_400,
    "4": _seedsColor.default.COLOR_YELLOW_500,
    "5": _seedsColor.default.COLOR_BLUE_400,
    "6": _seedsColor.default.COLOR_MAGENTA_400,
    "7": _seedsColor.default.COLOR_GREEN_400,
    "8": _seedsColor.default.COLOR_ORANGE_400,
    "9": _seedsColor.default.COLOR_RED_700,
    "10": _seedsColor.default.COLOR_TEAL_700,
    "11": _seedsColor.default.COLOR_PURPLE_700,
    "12": _seedsColor.default.COLOR_PINK_700,
    "13": _seedsColor.default.COLOR_YELLOW_800,
    "14": _seedsColor.default.COLOR_BLUE_700,
    "15": _seedsColor.default.COLOR_MAGENTA_700,
    "16": _seedsColor.default.COLOR_GREEN_700,
    "17": _seedsColor.default.COLOR_ORANGE_700,
    "18": _seedsColor.default.COLOR_RED_400,
    "19": _seedsColor.default.COLOR_GREEN_200,
    "20": _seedsColor.default.COLOR_YELLOW_200
  },
  PLACEHOLDER: _seedsColor.default.COLOR_NEUTRAL_800
};